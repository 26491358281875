<template>
  <div>
    <div class="search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="8" >
            <a-form-model-item 
                prop="financial_account_id" label="财务账户">
              <a-select placeholder="请选择账户"
                   v-model="searchForm.financial_account_id"
                   @change="changeAccount">
                <a-select-option v-if="!isFinanceManage" key="" value="">不限</a-select-option>
                <a-select-option v-for="(item, index) in financeAccountList"
                     :value="item.id"
                     :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="card_no" label="钱包编号">
              <a-input allowClear v-model="searchForm.card_no" placeholder="请输入钱包编号"></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item prop="applicant_phone" label="手机号">
              <a-input allowClear v-model="searchForm.applicant_phone" placeholder="请输入申请人手机号"></a-input>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="8">
            <a-form-model-item prop="reviewer_staff_id" label="开票人">
              <a-select allowClear
                show-search
                placeholder="请输入开票的员工姓名"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="getStaffList"
                v-model="searchForm.reviewer_staff_id"
              >
                <a-select-option
                  v-for="(item, index) in staffList"
                  :key="index"
                  :value="item.staff_id"
                >{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="invoice_type" label="抬头类型">
              <a-select v-model="searchForm.invoice_type" placeholder="抬头类型" style="width:100%;">
                <a-select-option
                  v-for="(item,index) in lookUpType"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="invoice_name" label="抬头名称">
              <a-input allowClear v-model="searchForm.invoice_name" placeholder="请输入抬头名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="corporate_duty_paragraph" label="税号">
              <a-input allowClear v-model="searchForm.corporate_duty_paragraph" placeholder="请输入税号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="apply_time_arr" label="申请日期">
              <a-range-picker v-model="searchForm.apply_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="open_time_arr" label="开票日期">
              <a-range-picker v-model="searchForm.open_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="text-right mt-1">
          </a-col>
          <a-col :span="8" class="text-right mt-1">
            <a-button type="primary" @click="handlerSearch" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table
      ref="orderTableRef"
      id="orderTableID"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">钱包充值发票列表</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
            <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          </a-tabs>
        </div>
      </template>

      <template #operation="{record}">
        <div class="flex justify-around">
          <a-button class="p-0" type="link" @click="handlerDesc(record)">详情</a-button>
          <template v-if="record.audit_status == 1 || record.audit_status == 5">
            <a-button class="p-0" type="link"
              v-if="canAutoOpen && hasAuthAudit"
              @click="handlerUploadInvoce(record, 'audit')">审核</a-button>
            <a-button class="p-0" type="link"
              v-if="hasAuthOpen"
              @click="handlerUploadInvoce(record, 'open')">上传发票</a-button>
          </template>
        </div>
      </template>
    </base-table>

    <!-- 发票详情 -->
    <InvoiceDetail
      v-if="isShowInvoiceDetail"
      :show.sync="isShowInvoiceDetail"
      :requestID="transformDetailID"
    />

    <!-- 发票上传 -->
    <InvoiceUpload
      v-if="isShowInvoiceUpload"
      :show.sync="isShowInvoiceUpload"
      :requestID="transformDetailID"
      :type="operateType"
    />
  </div>
</template>

<script>
import moment from "moment"
import pageData from "./columns"
import InvoiceDetail from "./components/detail"
import InvoiceUpload from "./components/upload"
import {
  getTopupList,
} from "@/api/invoice/topup-invoice.js"
import { getStaffList } from "@/api/customer.js"
import { checkAuth, isFinanceManage, getFinanceList } from "@/utils/authData.js";

export default {
  components: { InvoiceDetail, InvoiceUpload },
  data() {
    return {
      isFinanceManage: isFinanceManage(),
      hasAuthAudit: checkAuth("topup_invoice:audit"),
      hasAuthOpen: checkAuth("topup_invoice:open"),
      staffList: [],
      transformDetailID: "",
      isShowInvoiceDetail: false,
      isShowInvoiceUpload: false,
      ...pageData,
      activeKey: 0,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      total: 0,
      tableData: [],
      lookUpType: [
        { id: 0, name: "全部" },
        { id: 1, name: "企业" },
        { id: 2, name: "个人" },
      ],
      //（1-未开票，2-已开票）
      searchForm: {
        financial_account_id: undefined,
        // applicant_phone: "",
        audit_status: 0,
        card_no: "",
        reviewer_staff_id: undefined,
        apply_time_arr: [],
        apply_time_start: "",
        apply_time_end: "",
        audit_time_arr: [],
        audit_time_start: "",
        audit_time_end: "",
        open_time_arr: [],
        open_time_start: "",
        open_time_end: "",
        // 抬头类型 （0-全部，1-企业，2-个人）
        invoice_type: 0,
        // 	公司/抬头名称
        invoice_name: "",
        // 公司税号
        corporate_duty_paragraph: "",
        page: 1,
        page_count: 20,
      },
      financeAccountList: [],

      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "待审核" },
        { type_id: 2, type_name: "已审核" },
        { type_id: 4, type_name: "开票中" },
        { type_id: 5, type_name: "开票失败" },
        { type_id: 6, type_name: "开票成功" },
      ],

      canAutoOpen: false,
    }
  },
  async mounted() {
    const accountList = await getFinanceList()

    if(accountList && accountList.length){
      this.financeAccountList = accountList
      if(this.isFinanceManage){
        if(accountList.length == 1){
          this.searchForm.financial_account_id = accountList[0].id
          this.changeAccount()
        }
      }else{
        this.searchForm.financial_account_id = ""
        this.changeAccount()
      }
    }
  },
  methods: {
    moment,
    handlerTypeChange(e) {
      this.activeKey = e
      this.searchForm.audit_status = e
      this.searchForm.page = 1
      this.initData()
    },
    changeAccount(){
      const account = this.financeAccountList.find(ac=>{
        return ac.id == this.searchForm.financial_account_id
      })
      this.canAutoOpen = !!(account && account.support_auto_open_invoice == 1)
      this.handlerSearch()
    },
    async initData() {
      if (this.isFinanceManage && !this.searchForm.financial_account_id) {
        return this.$message.warning('请选择财务账户')
      }
      if (this.searchForm.apply_time_arr.length > 0) {
        this.searchForm.apply_time_start = this.searchForm.apply_time_arr[0]
        this.searchForm.apply_time_end = this.searchForm.apply_time_arr[1]
      }else{
        this.searchForm.apply_time_start = ''
        this.searchForm.apply_time_end = ''
      }
      if (this.searchForm.open_time_arr.length > 0) {
        this.searchForm.open_time_start = this.searchForm.open_time_arr[0]
        this.searchForm.open_time_end = this.searchForm.open_time_arr[1]
      }else{
        this.searchForm.open_time_start = ''
        this.searchForm.open_time_end = ''
      }
      const { data, code } = await getTopupList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 操作人
    async getStaffList(value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
      if(this.financeAccountList.length == 1){
        this.searchForm.financial_account_id = this.financeAccountList[0].id
      } else {
        this.searchForm.financial_account_id = undefined
        this.tableData = []
      }
      
      this.initData()
    },
    handlerDesc(row) {
      this.transformDetailID = row.id
      this.isShowInvoiceDetail = true
    },
    handlerUploadInvoce(row, type) {
      this.operateType = type
      
      this.transformDetailID = row.id
      this.isShowInvoiceUpload = true
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
import ajax from "@/utils/ajax.js";

/**
 * 钱包充值发票：发票列表
 * @param {*} params
 * @returns
 */
export function getTopupList(params) {
  return ajax.post("/RechargeInvoiceApply/getList", params);
}

/**
 * 钱包充值发票：发票详情
 * @param {*} params
 * @returns
 */
export function getTopupDesc(params) {
  return ajax.post("/RechargeInvoiceApply/detail", params);
}


/**
 * 钱包充值发票：审核
 * @param {*} params
 * @returns
 */
export function auditTopup(params) {
  return ajax.post("/RechargeInvoiceApply/audit", params);
}

/**
 * 钱包充值发票：开票
 * @param {*} params
 * @returns
 */
export function openTopup(params) {
  return ajax.post("/RechargeInvoiceApply/open", params);
}


import { formatInvoceType } from "@/utils/type";
export const rowKey = "id";

export const columns = [
  // {
  //   title: "申请ID",
  //   dataIndex: "id",
  //   align: "center",
  // },
  {
    title: "钱包编号",
    dataIndex: "card_no",
    align: "center",
    width: "15%",
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center",
  },
  {
    title: "开票日期",
    dataIndex: "open_time",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "audit_status",
    align: "center",
    width: "9%",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatInvoceType(val),
  },
  {
    title: "操作人",
    dataIndex: "reviewer",
    align: "center",
    width: "15%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 180,
    slots: { customRender: "operation" },
  },
];

export default {
  rowKey,
  columns,
};

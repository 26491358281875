<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="950px"
      title="发票详情"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <h3 class="mt-3 title-bg">充值记录</h3>
      <base-table
        ref="invoiceRef"
        rowKey="invoiceTableRowKey"
        :columnsData="invoiceColumns"
        :tableData="invoiceTableData"
      >
      </base-table>

      <div class="footer">
        <h3 class="mt-3 title-bg">用户申请发票信息</h3>
        <a-form-model
          layout="horizontal"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
        >
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="发票金额">{{ invoiceInfo.open_amount }} 元</a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                label="邮箱">{{ invoiceInfo.email }}</a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" label="状态">
                <span>{{ formatInvoceType(invoiceData.audit_status) }}</span>
                <span v-if="invoiceData.audit_status == 6">（{{openTypeArr[invoiceData.open_type]}}）</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 企业 -->
          <template v-if="invoiceInfo.type==1">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">企业</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="抬头">{{invoiceInfo.name}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="税号">{{invoiceInfo.corporate_duty_paragraph}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="6">
                <a-form-model-item label="电话">{{ invoiceInfo.phone }}</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="开户行">{{invoiceInfo.open_bank}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="账号">{{invoiceInfo.bank_account}}</a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{span:2}" :wrapperCol="{span:22}" label="注册地址">{{invoiceInfo.registered_address}}</a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 个人 -->
          <template v-if="invoiceInfo.type==2">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">个人</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="抬头">{{invoiceInfo.name}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="电话">{{invoiceInfo.phone}}</a-form-model-item>
              </a-col>
            </a-row>
          </template>
          
          <!-- 财务处理 -->
          <template v-if="invoiceData.audit_status > 1">
            <h3 class="mt-3 title-bg">财务开票处理</h3>
            <a-row v-if="invoiceData.audit_status == 6">
              <a-col :span="24">
                <a-form-model-item :labelCol="{ span: 2 }" label="发票图">
                  <div class="flex">
                    <div v-for="(item, index) in imgList" :key="index">
                      <img v-if="!item.is_pdf" class="img-class" :src="item.file_url"
                          @click="handlerBigImg(item)" />
                      <a-button v-else class="p-0 mr-2" type="link" 
                          @click="handlerBigImg(item)">PDF发票{{index?index+1:''}}</a-button>
                    </div>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :span="24">
                <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="审核备注">
                  {{invoiceData.audit_remarks || '无'}}
                </a-form-model-item>
                <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="开票备注">
                  {{invoiceData.open_remarks || '无'}}
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 开票失败记录 -->
          <template v-if="invoiceData.open_fail_log && invoiceData.open_fail_log.length">
            <h3 class="mt-3 title-bg">开票失败记录</h3>
            <base-table class="mb-2"
              :columnsData="columns_fail_log"
              :tableData="invoiceData.open_fail_log"
            ></base-table>
          </template>
          
        </a-form-model>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="发票图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="handlerCancel"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { formatInvoceType } from "@/utils/type";
import {
  getTopupDesc,
  // openInvoce,
} from "@/api/invoice/topup-invoice.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: Number,
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    return {
      formatInvoceType,
      previewVisible: false,
      previewImage: "",
      form: {},
      imgList: [],
      invoiceData: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      invoiceTableData: [],
      invoiceColumns: [
        {
          title: "订单号",
          dataIndex: "order_no",
          align: "center",
        },
        {
          title: "发生时间",
          dataIndex: "generation_time",
          align: "center",
        },
        {
          title: "面额",
          dataIndex: "denomination",
          align: "center",
          width: "90px",
        },
        {
          title: "支付金额",
          dataIndex: "price",
          align: "center",
          width: "90px",
        },
      ],
      invoiceInfo: {},

      isAutoOpen: false,
      openTypeArr: {
        1: "人工开票",
        2: "自动开票",
      },

      columns_fail_log: [
        {
          title: "失败时间",
          dataIndex: "fail_time",
          align: "center",
          width: 180,
        },
        {
          title: "失败原因",
          dataIndex: "fail_reason",
          align: "center",
        },
      ]
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getTopupDesc({ id: this.requestID })
      if (code == 0) {
        this.invoiceData = data
        this.invoiceTableData = data.recharge_order_list
        this.invoiceTableData.map((item, index) => {
          item["invoiceTableRowKey"] = index
          return item
        })
        this.invoiceInfo = data.invoice_info
        this.imgList = data.invoice_info.invoice_files.map(el=>{
          el.is_pdf = /\.pdf$/i.test(el.file_url)
          return el
        })
      }
    },
    handlerBigImg(e) {
      if(e.is_pdf){
        window.open(e.file_url, "_blank");
      }else{
        this.previewImage = e.file_url
        this.previewVisible = true
      }
    },
    handlerCancel() {
      this.previewVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.preview-class /deep/ .ant-modal-body {
  padding: 10px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}

.footer {
  margin-top: 20px;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>
